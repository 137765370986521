interface SuccessStory {
  imgUrl: string;
  story: string;
  author: string;
}

export const SuccessStories: SuccessStory[] = [
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724141457/Frame_1214134128_32.png',
    story: `"Working from home made it impossible to focus on my tasks. Brainway helped me organize my day and now I can finish my tasks at work efficiently and still have enough ‘me time’ in the evening, leaving my work related stress behind."`,
    author: 'Justine M., 26',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724141457/Frame_1214134128_33.png',
    story:
      '"As a writer, I often found myself procrastinating on starting my new novels, I used to have a terrible writer’s block... Brainway has been a huge motivation boost I feel much more productive and creative at my work."',
    author: 'Simona S., 32',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724141457/Frame_1214134128_34.png',
    story:
      '"Because of my nasty scrolling habit, I used to postpone my homework and couldn’t retain any new information. I was about to drop out of uni... Brainway improved my productivity x1000. Now I am a proud master graduate!"',
    author: 'April,  26',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724141457/Frame_1214134128_37.png',
    story:
      '"I looove learning new languages, but I always struggled with staying focused. Since using Brainway, my learning habits have improved, and I’m lerning new information much easier. I am now learning Japanese! It’s going great."',
    author: 'Tom, 27',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724141457/Frame_1214134128_38.png',
    story:
      '"After starting a new job, I struggled to stay organized and I felt a lot of pressure. Brainway has made it so much easier to handle my workload and consistently meet deadlines without feeling overwhelmed. I feel much more in control of my tasks."',
    author: 'Brianne S., 30',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724141458/Frame_1214134128_39.png',
    story: `"I used to have a terrible time sticking to my fitness routine. I'd always find excuses and procrastinate my workouts. Brainway helped me set a consistent workout schedule and stick to it. I now do yoga every morning!"`,
    author: 'Hannah, 27',
  },
];
