import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import VerifiedBadge from 'assets/icons/verified-badge.svg';

interface SuccessCardProps {
  imgUrl: string;
  story: string;
  author: string;
}

const SuccessCard: FC<SuccessCardProps> = ({ imgUrl, story, author }) => (
  <CardContainer>
    <ImageContainer>
      <img
        src={imgUrl}
        alt="A smiling girl holding a book and sitting next to her dog"
      />
    </ImageContainer>
    <ReviewDetails>
      <StoryContainer>
        <p>{story}</p>
      </StoryContainer>
      <CardFooter>
        <AuthorContainer>
          <strong>{author}</strong>
          <StarsContainer>
            <StarIcons starCount={5} />
          </StarsContainer>
        </AuthorContainer>
        <VerifiedBadge />
      </CardFooter>
    </ReviewDetails>
  </CardContainer>
);

export default SuccessCard;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 21.438rem;
  min-height: 37.4375rem;
  padding: 1.5rem 1rem;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.625rem;
  border: 1px solid #eeeef0;
  background: #fcf7f1;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 19.313rem;
  border-radius: 0.625rem;
  margin-bottom: 0.75rem;

  img {
    object-fit: cover;
    border-radius: 0.625rem;
  }
`;

const ReviewDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between !important;
`;

const StoryContainer = styled.div`
  border-bottom: 1px solid rgba(142, 144, 154, 0.15);
  padding-bottom: 0.75rem;
  height: 167px;

  p {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
  }
`;

const AuthorContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;

  strong {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
`;

const CardFooter = styled.div`
  margin-top: 0.75rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const StarsContainer = styled.div`
  display: flex;
`;
