import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import VerifiedBadge from 'assets/icons/verified-badge.svg';
import { tablet } from 'styles/breakpoints';

const ReviewCard: FC = () => (
  <CardContainer>
    <ImageContainer>
      <StyledImage
        src="https://res.cloudinary.com/drfweekiv/image/upload/v1724141457/Frame_1214134087_4.png"
        alt="Happy man with a smart phone"
      />
    </ImageContainer>
    <StyledText>
      &quot;Managing three projects at once has been super overwhelming.
      Brainway helped me prioritize my tasks and set realistic deadlines. I am
      much more productive and feel more in control of my workload.&quot;
    </StyledText>
    <UserRatingContainer>
      <div>
        <UserName>Nate W., 29 </UserName>
        <StarsContainer>
          <StarIcons starCount={5} />
        </StarsContainer>
      </div>
      <VerifiedBadge />
    </UserRatingContainer>
  </CardContainer>
);

export default ReviewCard;

const CardContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 21.4375rem;
  padding: 1.25rem;
  border-radius: 0.625rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eeeef0;

  @media ${tablet} {
    margin-bottom: 1rem;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 16.4375rem;
  margin-bottom: 0.75rem;
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 0.625rem;
`;

const StyledText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  color: #000;
  margin: 0;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgb(142, 144, 154, 15%);
`;

const UserRatingContainer = styled.div`
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const UserName = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 0.5rem;
`;
const StarsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
