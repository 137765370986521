import React, { FC } from 'react';
import styled from 'styled-components';
import { useRouter } from 'apis/history';

interface CtaButtonProps {
  buttonText: string;
}

const CtaButton: FC<CtaButtonProps> = ({ buttonText }) => {
  const { goToLanding } = useRouter();

  return (
    <ButtonContainer onClick={() => goToLanding()}>
      <StyledCTA>{buttonText}</StyledCTA>
    </ButtonContainer>
  );
};

export default CtaButton;

const ButtonContainer = styled.div`
  width: 100%;
`;

const StyledCTA = styled.button`
  font-size: 1rem;
  color: #fff;
  width: 100%;
  max-width: 21.4375rem;
  min-width: 18rem;
  min-height: 3.5rem;
  padding: 0.81rem;
  font-weight: 400;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  background: #f87666;
  font-style: normal;
  box-shadow: 0px 0px 1px 0px rgba(40, 41, 61, 0.04),
    0px 2px 4px 0px rgba(96, 97, 112, 0.16);
`;
