import React, { FC } from 'react';
import styled from 'styled-components';
import ReviewCard from 'components/reviews/ReviewCard';
import TrustpilotStars from 'components/reviews/TrustpilotStars';
import TrustpilotStarsPartial from 'components/reviews/TrustpilotStarsPartial';
import CtaButton from 'components/reviews/CtaButton';
import {
  SectionHeading,
  SectionSubheading,
  StyledSection,
} from 'pages/reviews';
import { tablet } from 'styles/breakpoints';

const MainReviewSection: FC = () => (
  <StyledSection
    backgroundColor="#FCF7F1"
    sectionPadding="3.5rem 1rem"
    marginTop="0"
  >
    <SectionHeading marginBottom="0.25rem">
      Our customers’ reviews
    </SectionHeading>
    <HappyUsersContainer>
      <SectionSubheading marginBottom="0.62rem">
        Milions of happy users around the world
      </SectionSubheading>
      <RatingContainer>
        <StarContainer>
          <TrustpilotStars starCount={4} />
          <TrustpilotStarsPartial starCount={1} />
        </StarContainer>
        <RatingNumber>4.8</RatingNumber>
      </RatingContainer>
    </HappyUsersContainer>
    <div>
      <ReviewCard />
      <CtaButton buttonText="Start your journey" />
    </div>
  </StyledSection>
);

export default MainReviewSection;

const HappyUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;

  @media ${tablet} {
    margin-bottom: 1.25rem;
  }
`;

const RatingContainer = styled.div`
  display: flex;
`;

const StarContainer = styled.div`
  display: flex;
  gap: 0.41rem;
`;

const RatingNumber = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 0.5rem;
  color: #000;
`;
