interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060235/bw-1.mp4',
    title: 'Improved my life',
    description: `"Managing uni and work tasks was very chaotic for me. Brainway brought so much structure into my life!"`,
    name: 'Lilly L.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060236/bw-2.mp4',
    title: 'I beat work procrastination',
    description: `"I’ve struggled with procrastination when starting new projects. Brainway has helped me stay focused and efficient."`,
    name: 'Hugh B.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060236/bw-3.mp4',
    title: 'Productivity has skyrocketed',
    description: `"I used to get so distracted by scrolling that I couldn’t get anything done. Now, thanks to this app, I am unstoppable!"`,
    name: 'Anna B.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060236/bw-4.mp4',
    title: 'Stopped postponing my chores',
    description: `"I used to skip my chore days constantly. The app helped me overcome the stress and now I keep my house clean at all times!"`,
    name: 'Becca H.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060236/bw-5.mp4',
    title: 'Helped me with studies',
    description: `"This app helped me create a great study plan, and the reminders keep me on track. Grades have improved, I’m waay less stressed"`,
    name: 'Samantha J.',
  },
];
