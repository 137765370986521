import React, { FC } from 'react';
import {
  SectionHeading,
  SectionSubheading,
  StyledSection,
} from 'pages/reviews';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { SuccessStories } from 'utils/successStories';
import SuccessCard from './SuccessCard';
import styled from 'styled-components';
import CtaButton from './CtaButton';

const SuccessStoriesSection: FC = () => {
  const { isTablet } = useQuery();

  return (
    <StyledSection
      backgroundColor="#fff"
      sectionPadding="3.5rem 1rem"
      marginTop="0"
    >
      <SectionHeading marginBottom="0.25rem">
        Our users’ success stories
      </SectionHeading>
      <SectionSubheading marginBottom={isTablet ? '1.25rem' : '2.38rem'}>
        Discover how Brainway helps beat procrastination
      </SectionSubheading>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={1}
          spaceBetween={isTablet ? 15 : 22}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1110: {
              slidesPerView: 3,
            },
          }}
        >
          {SuccessStories.map((story, index) => (
            <SwiperSlide key={index}>
              <SuccessCard
                imgUrl={story.imgUrl}
                author={story.author}
                story={story.story}
              />
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
      <ButtonContainer>
        <CtaButton buttonText="Start your journey" />
      </ButtonContainer>
    </StyledSection>
  );
};

export default SuccessStoriesSection;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 67.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) and (max-width: 1110px) {
    max-width: 44.375rem;
  }
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    margin-bottom: 1.75rem;
    display: flex;
    justify-content: center;

    @media ${tablet} {
      margin-bottom: 1.5rem;
    }
  }

  .swiper-wrapper {
    display: flex;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: rgba(115, 137, 156, 0.5);
    width: 0.5rem;
    height: 0.5rem;
    &-active {
      background-color: #f87666;
    }
  }
`;

const ButtonContainer = styled.div`
  max-width: 21.4375rem;
  width: 100%;
  margin-top: 1rem;

  @media ${tablet} {
    margin-top: 0.5rem;
  }
`;
