import React, { FC } from 'react';
import styled from 'styled-components';
import {
  SectionHeading,
  SectionSubheading,
  StyledSection,
} from 'pages/reviews';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { userReviews } from 'utils/userReviews';
import UserReviewCard from './UserReviewCard';
import CtaButton from './CtaButton';

const SatisfiedUsersSection: FC = () => {
  const { isTablet } = useQuery();
  return (
    <StyledSection
      backgroundColor="#FCF7F1;"
      sectionPadding="3.5rem 1rem"
      marginTop="0"
    >
      <SectionHeading marginBottom="0.25rem">
        Over 1.2 million satisfied users
      </SectionHeading>
      <SectionSubheading marginBottom={isTablet ? '1.5rem' : '2.38rem'}>
        Read about our users’ productivity journey
      </SectionSubheading>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 15 : 22}
        >
          {userReviews.map((review, index) => (
            <SwiperSlide key={index}>
              <UserReviewCard
                key={index}
                author={review.author}
                comment={review.comment}
              />
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
      <ButtonContainer>
        <CtaButton buttonText="Start your journey" />
      </ButtonContainer>
    </StyledSection>
  );
};

export default SatisfiedUsersSection;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 67.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-slide {
    width: 100%;
    max-width: 21.438rem;
    margin-bottom: 1.75rem;
  }

  @media ${tablet} {
    .swiper-slide {
      width: 100%;
      max-width: 17.5rem !important;
      margin-bottom: 1.5rem !important;
    }
  }

  .swiper-pagination {
    position: relative !important;
    width: 100%;
    gap: 0.75rem;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    background-color: rgba(115, 137, 156, 0.5);
    &-active {
      background-color: #f87666;
      width: 0.5rem;
      height: 0.5rem;
    }
  }
`;

const ButtonContainer = styled.div`
  max-width: 21.4375rem;
  margin-top: 1rem;
  width: 100%;

  @media ${tablet} {
    margin-top: 0.5rem;
  }
`;
