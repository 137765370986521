interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'Rosey R.',
    comment:
      '4 months streak with Brainway, yayy!! It helped me fix issues I never thought I could overcome.',
  },
  {
    author: 'Emily H.',
    comment: `This app only takes me 10-15 minutes each day, and I'm already so much more productive.`,
  },
  {
    author: 'Emma P.',
    comment: `Thank you for a wonderful app. Feeling mentally stronger and doing much more for myself and my family.`,
  },
  {
    author: 'Monique',
    comment:
      'I find it impressive, how an app solved procrastination issues I had for my whole life, while nothing else worked.',
  },
  {
    author: 'Britney M.',
    comment:
      'Finally, an app that helps me beat procrastination with self love, instead of judgement. I love it.',
  },
  {
    author: 'Jacobs N.',
    comment:
      'I used to skip workouts and chore days. Finally, I\'m getting things done, and I even got promoted at work!',
  },
];
